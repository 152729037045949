<template>
	<div class="language-toggler dropdown" v-if="optionsForLanguage.length > 0">
		<b-dropdown variant="outline-secondary" size="sm" :text="getCurLanguageToDisplay" v-if="$screen.width >= 992">
			<b-dropdown-header id="dropdown-header-label">
				{{ FormMSG(10, 'Switch to another language') }}
			</b-dropdown-header>
			<b-dropdown-item-button v-for="lang in langArray" :key="lang.value" action @click="changeLanguage(lang)">
				{{ lang.text }}
			</b-dropdown-item-button>
		</b-dropdown>

		<b-dropdown size="sm" variant="outline-primary" class="mx-0" :disabled="isLangueChangeLoading" v-if="$screen.width < 992" no-caret>
			<template #button-content>
				<b-spinner v-if="isLangueChangeLoading" small />
				<globe-2 v-else :size="16" />
			</template>
			<b-dropdown-header id="dropdown-header-label">
				{{ FormMSG(10, 'Switch to another language') }}
			</b-dropdown-header>
			<b-dropdown-item v-for="lang in langArray" :key="lang.value" action :active="language === lang.value" @click="changeLanguage(lang)">
				{{ lang.text }}
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>

<script>
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import { Globe2 } from 'lucide-vue';
import globalMixin from '@/mixins/global.mixin';
import { updateLanguage } from '@/cruds/language.crud';
import _ from 'lodash';
import { noTilde } from '~utils';

// Variable doit être dans la query : https://github.com/graphql/graphiql/issues/156

export default {
	name: 'HeaderLanguageToggler',
	components: { Globe2 },
	mixins: [languageMessages, globalMixin],
	data: function () {
		return {
			// Initialize your apollo data
			erreur: {},
			myerr: '',
			shared: store.state,
			language: 0,
			curArrayItem: { value: 0, text: 'English' },
			langArray: [{ value: 0, text: 'English' }],
			isLangueChangeLoading: false
		};
	},
	watch: {
		curArrayItem: {
			async handler(newVal) {
				let checkToken = store.getToken() ? store.getToken() : null;
				if (!_.isNil(checkToken)) {
					setTimeout(() => {
						localStorage.setItem('job-request-default-language-with-token', JSON.stringify(newVal));
					}, 100);
				}
				// return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		getCurLanguageToDisplay() {
			return this.curArrayItem.text;
		},
		optionsForLanguage() {
			//console.log("in optionsForLanguage, appLanguage:",store.appLanguage())
			// const isProdEnv = process.env.VUE_APP_GQL.includes('svc'); // meaning in production only allow validated language choice:   const isProdEnv = process.env.VUE_APP_GQL.includes("svc");
			//console.log("PRODUCTION_ENVIRONMENT :", isProdEnv);
			if (process.env.VUE_APP_RUNNING_MODE === 'PROD' && !store.isTranslator()) {
				this.langArray = this.FormMenu(10001);
			} else if ((process.env.VUE_APP_RUNNING_MODE === 'PROD' && store.isTranslator()) || process.env.VUE_APP_RUNNING_MODE !== 'PROD') {
				this.langArray = this.FormMenu(1);
			}

			this.langArray.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			if (!store.getToken()) {
				/**
				 * if no token <=> system is logged out
				 * set the value of the current language according to the last stored indication in job-request
				 */
				if (store.getJobRequestLangWithToken()) {
					this.language = store.getJobRequestLangWithToken().value;
				} else {
					this.language = store.getJobRequestLangNoToken().value;
				}
			} else {
				this.language = store.appLanguage();
			}
			// then get the exact message for the current used language.
			for (let i = 0; i < this.langArray.length; i++) {
				if (this.langArray[i].value === this.language) {
					this.curArrayItem = this.langArray[i];
					break;
				}
			}
			return this.langArray;
		}
	},
	methods: {
		async changeLanguage(newChoice) {
			if (this.$isPwa() && this.$isIOSDevice()) {
				const action = async () => {
					this.actionForChangeLanguagePWA(newChoice);
				};
				this.confirmModal(action, this.FormMSG(11, 'The app will disconnect to support changing your language. Want to continue ?'));
			} else {
				await this.showLoader(this.actionForChangeLanguageWeb, 'default-container', false, newChoice);
			}
		},

		async actionForChangeLanguageWeb(newChoice) {
			// console.log("new choice:", newChoice);
			this.isLangueChangeLoading = true;

			for (let i = 0; i < this.langArray.length; i++) {
				if (this.langArray[i].value == newChoice.value) {
					this.curArrayItem = this.langArray[i];

					this.language = this.langArray[i].value;
					break;
				}
			}
			const LanguageId = parseInt(this.language, 10);
			let pathName = this.$route.name;
			let checkToken = store.getToken();
			if (!checkToken) {
				/**
				 * if no token <=> system is logged out
				 * set the indication of the language to use in job-request no token;
				 */
				if (store.getJobRequestLangWithToken()) store.removeJobRequestLangWithToken();
				store.setJobRequestLangNoToken(this.curArrayItem);
			} else {
				/**
				 * if token <=> system is logged in
				 * set the indication of the language to use in job-request with token;
				 */
				if (store.getJobRequestLangNoToken()) store.removeJobRequestLangNoToken();
				store.setJobRequestLangWithToken(this.curArrayItem);
			}
			if (pathName === 'jobRequest' && !checkToken) {
				await store.reloadLanguagesNotConnected(LanguageId);
				NProgress.start();
				this.$router.go();
				this.isLangueChangeLoading = false;
			} else {
				try {
					const UpdAppLanguage = await updateLanguage(LanguageId);
					NProgress.start();
					const response = await store.onNewToken(this.$apollo.provider.defaultClient, UpdAppLanguage); // executer onNewToken avant de storer le nouveau projet, sinon supprimé
					if (response) {
						this.myerr = '';
						this.$router.go();
					}
				} catch (error) {
					this.erreur = error;
				} finally {
					this.isLangueChangeLoading = false;
				}
			}
		},

		async actionForChangeLanguagePWA(newChoice) {
			// console.log("new choice:", newChoice);
			this.isLangueChangeLoading = true;

			for (let i = 0; i < this.langArray.length; i++) {
				if (this.langArray[i].value == newChoice.value) {
					this.curArrayItem = this.langArray[i];
					this.language = this.langArray[i].value;
					break;
				}
			}

			const LanguageId = parseInt(this.language, 10);

			try {
				const UpdAppLanguage = await updateLanguage(LanguageId);
				store.state.myProfile.appLanguage = parseInt(this.language, 10);
				const response = await store.onNewToken(this.$apollo.provider.defaultClient, UpdAppLanguage); // executer onNewToken avant de storer le nouveau projet, sinon supprimé
				if (response) {
					await this.provideFormsMap(true);
					await this.provideMenuMap(true);

					console.log({ 'this.$isPwa()': this.$isPwa() });

					this.myerr = '';
					await store.onLogout(null, false);
					document.location.href = '/';
				}
			} catch (error) {
				this.erreur = error;
			} finally {
				this.isLangueChangeLoading = false;
			}
		}
	}
};
</script>

<style>
.language-toggler .dropdown-menu {
	/* margin: 20px; */
	padding: 0;
}
</style>
