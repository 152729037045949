<template>
	<button display="sm" mobile="true" type="button" class="d-lg-none backbtn-toggler" @click="$router.back()">
		<ArrowIcon />
	</button>
</template>

<script>
import ArrowIcon from '@/components/icons/arrow';

export default {
	name: 'BackInHistoryBtnComponent',
	components: { ArrowIcon }
};
</script>

<style lang="scss" scoped>
.backbtn-toggler {
	border: none;
	background-color: transparent;
	svg {
		transform: rotate(90deg);
	}
}
</style>
